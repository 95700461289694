<template>
    <div class="row">
        <loading-flux :value="loadingPermisos"></loading-flux>

        <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
            {{ mensaje.texto }}
        </b-alert>
        <div class="d-flex">
            <div class="card-title col-auto me-auto">
                Modulos
            </div>
            <button
                v-if="rolModuloPermiso.escritura"
                class="btn btn-primary col-auto"
                @click="abrirModalNuevo"
            >
                <i class="mdi mdi-plus me-1"></i>
                Nuevo
            </button>
        </div>
        <div class="d-flex"></div>

        <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Mostrar&nbsp;
                        <b-form-select
                            class="form-select form-select-sm"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                        ></b-form-select>
                    </label>
                </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                    <label class="d-inline-flex align-items-center">
                        Buscar:
                        <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Buscar..."
                            class="form-controlModuloPermiso form-controlModuloPermiso-sm ms-2"
                        ></b-form-input>
                    </label>
                </div>
            </div>
            <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
            <b-table
                class="datatables tabla-personalizada"
                :items="rolModuloPermisos"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                select-mode="single"
                selectable
                @filtered="onFiltered"
                :busy="rolModuloPermisosCargando"
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>
                <template
                    #cell(escritura)="data"
                    v-if="rolModuloPermiso.escritura"
                >
                    <div class="form-check form-switch form-switch-md mb-3">
                        <input
                            @click="actualizar_permiso(data.item, 'escritura')"
                            class="form-check-input"
                            type="checkbox"
                            :checked="data.item.escritura"
                        />
                    </div>
                </template>
                <template
                    #cell(lectura)="data"
                    v-if="rolModuloPermiso.escritura"
                >
                    <div class="form-check form-switch form-switch-md mb-3">
                        <input
                            @click="actualizar_permiso(data.item, 'lectura')"
                            class="form-check-input"
                            type="checkbox"
                            :checked="data.item.lectura"
                        />
                    </div>
                </template>
                <template
                    #cell(activo)="data"
                    v-if="rolModuloPermiso.escritura"
                >
                    <div class="form-check form-switch form-switch-md mb-3">
                        <input
                            @click="actualizar_permiso(data.item, 'activo')"
                            class="form-check-input"
                            type="checkbox"
                            :checked="data.item.activo"
                        />
                    </div>
                </template>
                <!-- <template #cell(lectura)="data">
                    <span
                        :class="color(data.item.lectura)"
                        class="badge font-size-13"
                        >{{ data.item.lectura == 1 ? "Si" : "No" }}</span
                    >
                </template>
                <template #cell(escritura)="data">
                    <span
                        :class="color(data.item.escritura)"
                        class="badge font-size-13"
                        >{{ data.item.escritura == 1 ? "Si" : "No" }}</span
                    >
                </template>
                <template #cell(activo)="data">
                    <span
                        :class="color(data.item.activo)"
                        class="badge font-size-13"
                        >{{ data.item.activo == 1 ? "Si" : "No" }}</span
                    >
                </template> -->
                <template
                    #cell(accion)="data"
                    v-if="rolModuloPermiso.escritura"
                >
                    <i
                        class="bx bx-edit h3 text-primary pointer me-2"
                        @click="abrirModalEditar(data.item)"
                    ></i>
                    <i
                        class="bx bx-trash h3 text-primary pointer"
                        @click="eliminarRolModuloPermisoLocal(data.item)"
                    ></i>
                </template>
            </b-table>
        </div>
        <div class="row">
            <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                        ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Rol Modulo Permiso"
            hide-footer
            size="lg"
        >
            <loading-flux :value="loading"></loading-flux>

            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="moduloPermiso">Modulo</label>
                            <multiselect
                                v-model="$v.form.moduloPermiso.$model"
                                :options="moduloPermisos"
                                placeholder="Seleccionar Modulo"
                                value="modulo_permiso_id"
                                label="nombre_modulo_permiso"
                                :searchable="true"
                                :loading="moduloPermisosCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.moduloPermiso.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="
                                    submitted && !$v.form.moduloPermiso.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="lectura">Lectura</label>
                            <div
                                class="form-check form-switch form-switch-lg mb-3"
                            >
                                <input
                                    v-model="$v.form.lectura.$model"
                                    class="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="escritura">Escritura</label>
                            <div
                                class="form-check form-switch form-switch-lg mb-3"
                            >
                                <input
                                    v-model="$v.form.escritura.$model"
                                    class="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="activo">Activo</label>
                            <div
                                class="form-check form-switch form-switch-lg mb-3"
                            >
                                <input
                                    v-model="$v.form.activo.$model"
                                    class="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button
                        v-if="rolModuloPermiso.escritura"
                        type="submit"
                        variant="success"
                        class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {
    rolModuloPermisoMethods,
    rolModuloPermisoComputed,
} from "@/state/helpers";
import { moduloPermisoMethods, moduloPermisoComputed } from "@/state/helpers";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: { Multiselect },
    props: {
        rol: Object,
    },
    data() {
        return {
            loading: false,
            loadingPermisos: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "nombre_modulo_permiso",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "nombre_rol",
                    label: "Rol",
                    sortable: true,
                },
                {
                    key: "nombre_modulo_permiso",
                    label: "Modulo",
                    sortable: true,
                },
                {
                    key: "lectura",
                    sortable: true,
                },
                {
                    key: "escritura",
                    sortable: true,
                },
                {
                    key: "activo",
                    sortable: true,
                },
            ],
            rolModuloPermisoSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            rolModuloPermisos: [],
            rolModuloPermisosCargando: false,
            moduloPermisos: [],
            moduloPermisosCargando: false,
            form: {
                moduloPermiso: null,
                lectura: null,
                escritura: null,
                activo: null,
            },
            rolModuloPermiso: {},
        };
    },
    validations: {
        form: {
            moduloPermiso: { required },
            lectura: {},
            escritura: {},
            activo: {},
        },
    },
    mounted() {
        this.obtenerRolModuloPermisosLocal();
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...rolModuloPermisoMethods,
        ...moduloPermisoMethods,
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.obtenerModuloPermisosLocal();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalEditar(datos) {
            this.rolModuloPermisoSeleccionado = datos;
            this.resetForm();
            this.obtenerModuloPermisosLocal();

            this.$v.form.lectura.$model = datos.lectura;
            this.$v.form.escritura.$model = datos.escritura;
            this.$v.form.activo.$model = datos.activo;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },

        obtenerRolModuloPermisosLocal() {
            this.rolModuloPermisosCargando = true;
            this.obtenerRolModuloPermisos(this.rol.codigo_rol)
                .then((res) => {
                    this.rolModuloPermisos = res.body;
                    this.totalRows = this.rolModuloPermisos.length
                    this.rolModuloPermisosCargando = false;
                })
                .catch((error) => {
                    this.rolModuloPermisosCargando = false;
                });
        },
        obtenerModuloPermisosLocal() {
            this.moduloPermisosCargando = true;
            this.obtenerModuloPermisosActivos()
                .then((res) => {
                    this.moduloPermisos = res.body;

                    if (this.esEditar) {
                        this.$v.form.moduloPermiso.$model = {
                            modulo_permiso_id: this.rolModuloPermisoSeleccionado
                                .modulo_permiso_id,
                            nombre_modulo_permiso: this
                                .rolModuloPermisoSeleccionado
                                .nombre_modulo_permiso,
                        };
                    }

                    this.moduloPermisosCargando = false;
                })
                .catch((error) => {
                    this.moduloPermisosCargando = false;
                });
        },
        actualizar_permiso(item, permiso) {
            this.loadingPermisos = true

            let lectura = item.lectura
            let escritura = item.escritura
            let activo = item.activo
            const codigo_rol = item.codigo_rol
            const modulo_permiso_id = item.modulo_permiso_id

            if(permiso == 'escritura'){

                escritura = !item.escritura
            }
            if(permiso == 'lectura'){
                lectura = !item.lectura
            }
            if(permiso == 'activo'){
                activo = !item.activo
            }
            let rolModuloPermiso = {
                codigo_rol,
                modulo_permiso_id,
                escritura,
                lectura,
                activo
            }
            this.actualizarRolModuloPermiso(rolModuloPermiso)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";  
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Modulo actualizado correctamente!!!";
                    }
                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerRolModuloPermisosLocal();
                    this.loadingPermisos = false
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.loadingPermisos = false
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
        submit(e) {
            this.loading = true;
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }

            let codigo_rol = this.rol.codigo_rol;
            let modulo_permiso_id = "";
            if (this.esEditar) {
                codigo_rol = this.rolModuloPermisoSeleccionado.codigo_rol;
                modulo_permiso_id = this.rolModuloPermisoSeleccionado
                    .modulo_permiso_id;
            } else {
                modulo_permiso_id = this.$v.form.moduloPermiso.$model
                    .modulo_permiso_id;
            }
            const lectura = this.$v.form.lectura.$model;
            const escritura = this.$v.form.escritura.$model;
            const activo = this.$v.form.activo.$model;

            let nuevoRolModuloPermiso = {
                codigo_rol,
                modulo_permiso_id,
                lectura,
                escritura,
                activo,
            };

            if (this.esEditar) {
                this.actualizarRolModuloPermiso(nuevoRolModuloPermiso)
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Modulo actualizado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerRolModuloPermisosLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            } else {
                this.crearRolModuloPermiso(nuevoRolModuloPermiso)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Modulo creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El Rol Modulo ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerRolModuloPermisosLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                moduloPermiso: null,
                lectura: null,
                escritura: null,
                activo: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarRolModuloPermisoLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarRolModuloPermiso(item)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Modulo eliminado correctamente!!!";
                                    this.mostrarModal = false;
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerRolModuloPermisosLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        color(item) {
            if (item == 1) {
                return "badge-soft-success";
            } else {
                return "badge-soft-warning";
            }
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    watch: {
        
        // rolModuloPermisoSeleccionadoChange: {

        //     handler(newVal, oldVal) {
        //         if (newVal !== oldVal) {
        //             this.rolModuloPermisoSeleccionado = newVal;
        //         }
        //     },
        // },
    },
    computed: {
        ...authUsuarioComputed,
        // ...rolModuloPermisoComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>
