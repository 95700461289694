<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        <loading-flux :value="loading"></loading-flux>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                Roles
                            </div>
                            <button
                                v-if="rolModuloPermiso.escritura"
                                class="btn btn-primary col-auto"
                                @click="abrirModalNuevo"
                            >
                            <i class="mdi mdi-plus me-1"></i>
                                Nuevo
                            </button>
                        </div>
                        <div class="d-flex">
                            
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="datatables tabla-personalizada"
                                :items="roles"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="rolesCargando"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    </div>
                                </template>
                                <template #cell(activo)="data">
                                    <span
                                        :class="color(data.item.activo)"
                                        class="badge font-size-13"
                                        >{{ data.item.activo == 1 ? 'Si' : 'No' }}</span
                                    >
                                </template>
                                <template #cell(accion)="data" v-if="rolModuloPermiso.escritura">
                                    <i
                                        class="bx bx-edit h3 text-primary pointer me-2"
                                        @click="abrirModalEditar(data.item)"
                                    ></i>
                                    <i
                                        class="bx bx-trash h3 text-primary pointer"
                                        @click="
                                            eliminarRolLocal(data.item)
                                        "
                                    ></i>
                                     <i
                                        class="bx bx-check-shield h3 text-primary pointer"
                                        @click="
                                            abrirModalRolModuloPermiso(data.item)
                                        "
                                    ></i>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Rol"
            hide-footer
            size="md"
        >   
            
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="codigoRol">Código Rol</label>
                            <input
                                id="codigoRol"
                                v-model="$v.form.codigoRol.$model"
                                type="text"
                                class="form-control"
                                placeholder="Código Rol"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.codigoRol.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.codigoRol.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreRol">Nombre Rol</label>
                            <input
                                id="nombreRol"
                                v-model="$v.form.nombreRol.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre Rol"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.nombreRol.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.nombreRol.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="activo">Activo</label>
                            <div
                                class="form-check form-switch form-switch-lg mb-3"
                            >
                                <input
                                    v-model="$v.form.activo.$model"
                                    class="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button v-if="rolModuloPermiso.escritura" type="submit" variant="success" class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="mostrarModalRolSeleccionado"
            title="Agregar Modulo Permiso"
            hide-footer
            size="xl"
        >   

            <form @submit.prevent="submit">
                <div class="row">
                   <RolModuloPermiso :rol="rolSeleccionado" />
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModalRolSeleccionado = false"
                        >Cerrar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </v-container>
</template>
<script>
// import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { rolMethods, rolComputed } from "@/state/helpers";
import RolModuloPermiso from "./rol-modulo-permiso"
import Swal from "sweetalert2";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "Roles",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        // Layout,
        PageHeader,
        RolModuloPermiso
    },

    data() {
        return {
            loading: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            title: "Roles",
            items: [
                {
                    text: "Usuarios",
                },
                {
                    text: "Roles",
                    active: true,
                }
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "codigo_rol",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "codigo_rol",
                    label: "Código Rol",
                    sortable: true,
                },
                {
                    key: "nombre_rol",
                    sortable: true,
                },
                {
                    key: "activo",
                    sortable: true,
                },
                
            ],
            rolSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            roles: [],
            rolesCargando: false,
            form: {
                codigoRol: null,
                nombreRol: null,
                activo: null
            },
            mostrarModalRolSeleccionado: false,
            rolModuloPermiso:{}
        };
    },
    validations: {
        form: {
            codigoRol: { required },
            nombreRol: { required },
            activo: { },
        },
    },
    mounted() {
        this.obtenerRolesLocal();
        let permisos = permiso.obtenerPermisos(this.user,this.$router.currentRoute.name)
        this.rolModuloPermiso = permisos
    },
    methods: {
        ...rolMethods,
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalEditar(datos) {
            this.rolSeleccionado = datos;
            this.resetForm();

            this.$v.form.codigoRol.$model = datos.codigo_rol;
            this.$v.form.nombreRol.$model = datos.nombre_rol;
            this.$v.form.activo.$model = datos.activo;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalRolModuloPermiso(datos) {
            this.rolSeleccionado = datos;
            this.mostrarModalRolSeleccionado = true;
        },

        obtenerRolesLocal() {
            this.rolesCargando = true;
            this.obtenerRoles()
                .then((res) => {
                    this.roles = res.body;
                    this.totalRows = this.roles.length
                    this.rolesCargando = false;
                })
                .catch((error) => {
                    this.rolesCargando = false;
                });
        },

        submit(e) {
            this.loading = true
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false
                return;
            }

            let codigo_rol = "";
            if (this.esEditar) {
                codigo_rol = this.rolSeleccionado.codigo_rol;
            }else{
                codigo_rol = this.$v.form.codigoRol.$model;
            }

            const nombre_rol = this.$v.form.nombreRol.$model;
            const activo = this.$v.form.activo.$model;

            let nuevoRol = {codigo_rol,nombre_rol,activo}
            
            if (this.esEditar) {
                this.actualizarRol(nuevoRol)
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Rol actualizado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerRolesLocal();
                        this.loading = false
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false
                    });
            } else {
                this.crearRol(nuevoRol)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Rol creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El Rol ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerRolesLocal();
                        this.loading = false
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                codigoRol: null,
                nombreRol: null,
                activo: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarRolLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarRol(item.codigo_rol)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Rol eliminado correctamente!!!";
                                    this.mostrarModal = false;
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerRolesLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        color(item) {
            if (item == 1) {
                return "badge-soft-success";
            } else {
                return "badge-soft-warning";
            } 
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...authUsuarioComputed,
        // ...rolComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>

